<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute(); // useRoter không sử dụng được .name
import MethodService from "../../service/MethodService";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// pinia store
import { changeEdit } from "../../stores/changeEdit";
const storeChangeEdit = changeEdit();
import { ListService } from "../../stores/ListService";
const storeListService = ListService();
import { datePicker } from "../../stores/datePicker";
const storeDatePicker = datePicker();
import { isHaveDataDashboard } from "../../stores/HaveDataDashboard";
const storeIsHaveDataDashboard = isHaveDataDashboard();
import DashboardCard from "../../components/DashboardCardComponent.vue";
import SortCardDashboard from "../../components/SortCardDashboard.vue";
import Loading from "../../components/loading-hello.vue";
import { ListDashboardPopup } from "@/stores/ListDashboardPopup";
const listDashboardPopup = ListDashboardPopup();
import Swal from "sweetalert2";
import { Filter } from "mushroomjs";
const items = reactive({ value: [] });
const indexCard = ref(0);
const titleCard = ref("");
const idNewCard = ref("");
const showSortCard = ref(false);
const dashboardType = ref("service");
const dashboardServiceId = ref(route.params.id);
const loadingData = ref(true);
const loadingBtn = ref(false);
const isClickDownload = ref(true); // nút download đang hiển thị
const isBtnLoading = ref(false); // nút Đang download
const idRequestDownload = ref("");
const router = useRouter();

// lấy về danh sách card theo màn dịch vụ
const getAllCard = async () => {
  loadingData.value = true;
  items.value = [];
  indexCard.value = 0;
  try {
    const response = await mushroom.card.listAsync({
      filters: Filter.and(
        Filter.eq("dashboard", "service"),
        Filter.eq("service_id", route.params.id)
      ).build(),
      sort: "index",
    });
    if (response.result?.length > 0) {
      indexCard.value = response.result.length;
      items.value = [];
      response.result.forEach((item) => {
        items.value.push(item);
      });
      storeIsHaveDataDashboard.haveData();
    } else storeIsHaveDataDashboard.noData();
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    loadingData.value = false;
  }
};

const addCard = () => {
  const addDomCard = {
    id: Math.random() + "",
  };
  Swal.fire({
    title: t("t_input_card_title"),
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    confirmButtonText: t("t-add"),
    icon: "info",
    showLoaderOnConfirm: true,
    cancelButtonText: t("t-cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
    preConfirm: (title) => {
      if (!title) {
        Swal.showValidationMessage(t("t_please_enter_card_title"));
      } else {
        titleCard.value = title;
        indexCard.value = indexCard.value + 1;
        addDomCard.title = title;
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      await saveCard(titleCard.value, indexCard.value);
      addDomCard.id = idNewCard.value;
      await getAllCard();
    }
  });
};

const saveCard = async (titleCard, indexCard) => {
  const card_object = {
    dashboard: "service",
    title: titleCard,
    index: indexCard,
    service_id: route.params.id,
    header: {
      is_hidden: false,
    },
  };
  try {
    const newId = await mushroom.card.createAsync(card_object);
    if (newId) toastr.success(t("toastr_add_card_success"));
    idNewCard.value = newId.result;
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const reloadGridDashboard = () => {
  loadingData.value = true;
  setTimeout(() => {
    getAllCard();
  }, 1000);
};

const showSortCardTable = () => {
  showSortCard.value = !showSortCard.value;
};
const cancelShowSortCard = () => {
  showSortCard.value = false;
};

const reloadingData = () => {
  loadingData.value = true;
};
const reRenderCard = () => {
  //render lại card khi xoá 1 card-items
  KeyReRenderCard.value += 1;
};
const reRenderCardAfterOtionCard = async () => {
  await getAllCard();
};
const KeyReRenderCard = ref(0);
const unloadingData = () => {
  setTimeout(() => {
    loadingData.value = false;
  }, 2000);
};

const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  storeIsHaveDataDashboard.downloadData();
  const search = location.search.substring(1);
  const data = search
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : "";

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "service",
    service_id: route.params.id,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (error) {
    console.error("Có lỗi: %o", error);
  }
};

const checkStatus = async () => {
  console.log("cứ 5s gọi log 1 lần, id: ", idRequestDownload.value);
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
    console.log(
      "Thông tin request_download_dashboard trả về: %o",
      response.result.status
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_service.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};

const hashUrl = () => {
  var search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );

  router
    .push({
      path: window.location.pathname,
      query: queryForPush,
    })
    .catch(() => {});
  // router.push({
  //   path: window.location.pathname,
  //   query: queryForPush,
  // });
};

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    hashUrl();
    await getAllCard();
  }
);

onMounted(async () => {
  // xem lại code đang bị lỗi khi từ màn UC sang
  const urlParams = window.location.search.slice(1);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  } else {
    hashUrl();
  }
  if (urlParams.length > 0) {
    const searchParams = new URLSearchParams(urlParams);
    let startDate = searchParams.get("start_date");
    let stopDate = searchParams.get("stop_date");
    if (startDate?.length > 0 && stopDate?.length > 0) {
      localStorage.setItem("dateSelect", [startDate, stopDate]);
      storeDatePicker.setDatePicker([startDate, stopDate]);
    }
  }

  window.localStorage.removeItem("customer");
  await getAllCard();
  storeListService.getAllListService();
  await listDashboardPopup.getAllListDashboardPopup();
});
watch(
  () => route.params.id,
  async (newId) => {
    localStorage.setItem("urlOld", window.location.href);
    dashboardServiceId.value = newId;
    await getAllCard();
  }
);

watch(
  () => storeChangeEdit.showEdit,
  (newStoreChangeEdit) => {
    console.log("newStoreChangeEdit", newStoreChangeEdit);
    getAllCard();
  }
);
</script>

<template>
  <Layout>
    <div class="container-fluid dashboard-all">
      <loading v-if="loadingData"></loading>
      <div
        class="text-center no-card"
        v-show="!loadingData && items.value.length === 0"
      >
        <i
          style="
            font-size: 40px;
            opacity: 0.5;
            color: #e0ba00;
            margin-right: 10px;
          "
          class="ri-bank-card-line"
        ></i>
        <span>{{ $t("t-no-card") }}</span>
      </div>
      <div
        v-show="items.value.length > 0"
        v-for="item in items.value"
        :key="item.id"
      >
        <dashboard-card
          :cardId="item.id"
          :cardTitle="item.title"
          :headerOptions="item?.header"
          @reloadGridDashboard="reloadGridDashboard"
          @reloadingData="reloadingData"
          @unloadingData="unloadingData"
          @showSortCardTable="showSortCardTable"
          @reRenderCardAfterOtionCard="reRenderCardAfterOtionCard"
          @reRenderCard="reRenderCard"
          :key="KeyReRenderCard"
        ></dashboard-card>
      </div>
      <div
        class="add-card d-flex justify-content-end"
        v-if="storeChangeEdit.showEdit === true"
      >
        <el-button
          @click="addCard"
          type="button"
          style="height: 40px"
          class="btn btn-secondary btn-border"
        >
          {{ $t("t-add-card") }}
        </el-button>
      </div>
      <div
        class="download-card"
        v-if="storeChangeEdit.showEdit === false && items.value.length > 0"
      >
        <div>
          <el-button
            v-if="isClickDownload"
            @click="downloadCard"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
          >
            {{ $t("t-download") }}
          </el-button>
          <el-button
            v-if="isBtnLoading"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
            :loading="loadingBtn"
          >
            {{ $t("t_loading") }}...
          </el-button>
        </div>
      </div>
      <div v-if="showSortCard">
        <sort-card-dashboard
          :dashboardType="dashboardType"
          :dashboardServiceId="dashboardServiceId"
          @cancelShowSortCard="cancelShowSortCard"
          @reloadGridDashboard="reloadGridDashboard"
        ></sort-card-dashboard>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./dashboard.scss" />
